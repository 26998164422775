<template>
  <v-container>
    <div class="headline">
      E2EE Debug
    </div>
    <div v-for="account of accounts" :key="account.accountId">
      <span class="heading">{{`${account.accountId} (${account.virgilAccountIdentityId})`}}</span>
      <vue-json-pretty
        :data="account.e2eeHelper"
        :deep="0"
      />
      <div v-if="account.cards.length > 0">
        <v-card tile v-for="(card, index) in account.cards" :key="index" class="ma-2 pa-2">
          <vue-json-pretty
            :data="card"
          />
        </v-card>
      </div>
      <div v-else>
        E2EE not enabled or there are no active cards...
      </div>
    </div>
  </v-container>
</template>

<script>
  import VueJsonPretty from 'vue-json-pretty'
  import E2EEHelperCache from "../../services/e2ee/E2EEHelperCache";

  export default {
    name: "E2EEDebug",
    components: {
      VueJsonPretty
    },
    beforeMount() {
      this.load()
    },
    data() {
      return {
        accounts: []
      }
    },
    methods: {
      async load() {
        await this.$store.state.accountLoadedPromise

        let e2eeHelpers = await E2EEHelperCache.getAllE2EEHelpers()

        for(let e2eeHelper of e2eeHelpers) {
          await e2eeHelper.initPromise
          let tCards = await e2eeHelper.getAllCards()
          this.accounts.push(
            {
              accountId: e2eeHelper.accountId,
              virgilAccountIdentityId: e2eeHelper.virgilAccountIdentityId,
              cards: tCards,
              e2eeHelper: e2eeHelper
            }
          )
        }


        // let accounts = this.$store.state.account.account.emailRecipientFor.map(x => x)
        // accounts.unshift({
        //   accountId: this.$store.state.account.accountId
        // })
        //
        // for(let er4 of accounts) {
        //   let e2eeHelper = await E2EEHelperCache.getE2EEHelper(er4.accountId)
        //   await e2eeHelper.initPromise
        //
        //   let tCards = await e2eeHelper.getAllCards()
        //   this.accounts.push(
        //     {
        //       accountId: er4.accountId,
        //       virgilAccountIdentityId: e2eeHelper.virgilAccountIdentityId,
        //       cards: tCards,
        //       e2eeHelper: e2eeHelper
        //     }
        //   )
        // }
      }
    }
  }
</script>

<style scoped>

</style>