<template>
  <e2-e-e-debug />
</template>

<script>
  import E2EEDebug from "./E2EEDebug";
  export default {
    name: "Debug",
    components: {E2EEDebug}
  }
</script>

<style scoped>

</style>