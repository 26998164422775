var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("div", { staticClass: "headline" }, [_vm._v("\n    E2EE Debug\n  ")]),
      _vm._l(_vm.accounts, function(account) {
        return _c(
          "div",
          { key: account.accountId },
          [
            _c("span", { staticClass: "heading" }, [
              _vm._v(
                _vm._s(
                  account.accountId +
                    " (" +
                    account.virgilAccountIdentityId +
                    ")"
                )
              )
            ]),
            _c("vue-json-pretty", {
              attrs: { data: account.e2eeHelper, deep: 0 }
            }),
            account.cards.length > 0
              ? _c(
                  "div",
                  _vm._l(account.cards, function(card, index) {
                    return _c(
                      "v-card",
                      {
                        key: index,
                        staticClass: "ma-2 pa-2",
                        attrs: { tile: "" }
                      },
                      [_c("vue-json-pretty", { attrs: { data: card } })],
                      1
                    )
                  }),
                  1
                )
              : _c("div", [
                  _vm._v(
                    "\n      E2EE not enabled or there are no active cards...\n    "
                  )
                ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }